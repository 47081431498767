import React, { useState } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import locked from "../images/locked.svg";
import unlocked from "../images/unlocked.svg";

let PostImage = (node) => {
    if (node.frontmatter.featuredImage) {
        let fluid = node.frontmatter.featuredImage.childImageSharp.fluid;
        return (
            // <img
            //     className="image-project"
            //     srcSet={fluid.srcSet}
            //     src={fluid.src}
            //     alt=""
            //     width="466"
            //     height="466"
            // />
            <Img
                className="image-project"
                fluid={fluid}
                alt={node.frontmatter.title}
            />
        );
    } else {
        return null;
    }
};

let LockIcon = (node, success) => {
    return node.frontmatter.private ? ( // && !window.location.href.match(/\/private/)
        success ? (
            <img
                src={unlocked}
                alt="Requires Login"
                title="Requires Login"
                width="20"
                height="20"
                className="v-mid ml1 dib"
            />
        ) : (
            <img
                src={locked}
                alt="Requires Login"
                title="Requires Login"
                width="20"
                height="20"
                className="v-mid ml1 dib"
            />
        )
    ) : null;
};

const checkAuth = () => {
    return new Promise((resolve) => {
        if (typeof window !== "undefined") {
            fetch("/auth", { method: "GET" })
                .then((response) => response.json())
                .then((data) => {
                    // console.log("logged in?", data);
                    resolve(data.success);
                })
                .catch(() => {
                    console.log("bad server response");
                    resolve(false);
                });
        } else {
            resolve(false);
        }
    });
};

let PostItem = ({ node, success }, i) => {
    return (
        <li
            key={"post_" + i}
            className={"post-tile ma3 " + (i === 0 ? "" : "")}
        >
            <Link
                to={node.fields.slug}
                className="link color-text tc ma0 w-100"
            >
                {PostImage(node)}
                <div>
                    <h3 className="dib mv3 flex fw7 f5">
                        {node.frontmatter.title}
                    </h3>
                    {LockIcon(node, success)}
                </div>
                {/* <p>{node.excerpt}</p>  */}
            </Link>
        </li>
    );
};

let PostList = (props) => {
    const { projects } = props;

    // console.log("postlist", props);

    const [success, setSuccess] = useState(false);

    checkAuth().then((success) => {
        setSuccess(success);
    });

    return (
        <ul className="list post-list">
            {projects.map((item, i) => (
                <PostItem key={i} node={item.node} success={success} />
            ))}
        </ul>
    );
};

export { PostList, checkAuth };
