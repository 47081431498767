import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import { SiteHeader } from "../components/header";
import { Footer } from "../components/footer";
import { NavList, NavButton } from "../components/nav";
import { useStickyState } from "../components/useStickyState";
import "../styles/main.scss";

const Layout = (props) => {
  const { querydata, children } = props;
  // console.log("layout", props, querydata, children && children.props.id);
  const [radioValue, setRadioValue] = useStickyState("portfolio", "radioValue");
  const [lastList, setLastList] = useStickyState(null, "lastList");
  const [showMenu, setShowMenu] = useState(false);

  const menuClass = showMenu ? "show" : "hide";

  return (
    <div className="application flex flex-column flex-grow relative background-neutral">
      <Helmet title={querydata.site.siteMetadata.title} defer={false} />
      <Helmet>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:400|Montserrat:100,400,700,900"
          rel="preconnect"
        ></link>
      </Helmet>
      <SEO />
      <div className="flex justify-start justify-center-l flex-column flex-row-l min-vh-100">
        <header className="w-100 w-20-l">
          <div className="pa4">
            {/* w-100 w-20-l fixed-l left-0 vh-100-l to make this nav fixed and responsive. Needs to have some kind of vertical wrap. */}
            <div className="flex justify-between">
              <Link
                to="/"
                className="link color-text site"
                onClick={() => {
                  setRadioValue("portfolio");
                }}
              >
                <SiteHeader headertext={querydata.site.siteMetadata.title} />
              </Link>
              <NavButton
                className="ml4"
                onClick={() => setShowMenu(!showMenu)}
              />
            </div>
            <div className={["ease", menuClass].join(" ")}>
              <p className="f5 pt3 mt2 i lato">
                <span className="dib">I'm a designer /&nbsp;</span>
                <span className="dib">developer /&nbsp;</span>
                <span className="dib">maker of things.</span>
              </p>
              <div className="flex mt4 mb0 br2">
                <NavList
                  radioValue={radioValue}
                  setRadioValue={setRadioValue}
                  lastList={lastList}
                  setLastList={setLastList}
                  currentId={children && children.props.id}
                />
              </div>
            </div>
          </div>
        </header>

        <main className={"page f5 z-1 pa3 flex flex-grow"}>
          <div className="center mw-100 flex flex-column">
            {React.cloneElement(children, {
              radioValue: radioValue,
              setRadioValue: setRadioValue,
            })}
          </div>
        </main>

        <footer className="w-100 w-20-l flex items-end justify-end-l">
          <div className="fixed-l right-0 bottom-0 w-100 w-20-l tr">
            <Footer headertext={querydata.site.siteMetadata.title} />
          </div>
        </footer>
      </div>
    </div>
  );
};

const LayoutQuery = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(querydata) => <Layout querydata={querydata} {...props} />}
    />
  );
};

export { LayoutQuery as Layout };
