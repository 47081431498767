import React, { useEffect } from "react";

const Footer = (props) => {
    useEffect(() => {
        console.clear();
        console.log(
            `/////////////////////////////\n
// Design and code by Owen //\n
/////////////////////////////`
        );
    }, []);

    return <div className="f7 ph4 pv4 i">Design and Code by Owen</div>;
};

export { Footer };
