import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { checkAuth } from "./post-list";

const listPages = [
  {
    label: "Portfolio",
    value: "portfolio",
    link: "/",
    type: "link",
  },
  {
    label: "Sculpture",
    value: "sculpture",
    link: "/sculpture",
    type: "link",
  },
  {
    label: "Interaction",
    value: "interaction",
    link: "/interaction",
    type: "link",
  },
  {
    label: "Writing",
    value: "writing",
    link: "/writing",
    type: "link",
  },
  {
    label: "Strategy",
    value: "planning",
    link: "/strategy",
    type: "link",
  },
];
const items = [
  {
    label: "About",
    value: "about_me",
    link: "/about",
    type: "link",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
    link: "https://linkedin.com/in/owenschoppe",
    type: "external",
  },
  {
    label: "GitHub",
    value: "github",
    link: "https://github.com/owenschoppe",
    type: "external",
  },
  {
    label: "Medium",
    value: "medium",
    link: "https://medium.com/@owen.schoppe",
    type: "external",
  },
  {
    label: "Login",
    alt_label: "Logout",
    value: "login",
    link: "/login",
    alt_link: "/logout",
    type: "login",
  },
  //   {
  //     label: "",
  //     value: "",
  //     link: "",
  //     type: "null",
  //   },
];

const NavItem = ({ item, radioValue, setRadioValue }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(radioValue === item.value ? true : false);
  }, [radioValue, setChecked, item]);

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    checkAuth().then(setAuth);
  }, []);

  const types = {
    link: (
      <Link
        to={item.link}
        className={
          checked ? "nav-link selected color-text" : "nav-link color-text"
        }
        aria-current={checked ? "page" : false}
      >
        {checked ? (
          <span className="assistive-text">Current page: </span>
        ) : null}
        {item.label}
      </Link>
    ),
    login: (
      <a
        href={auth ? item.alt_link : item.link}
        onClick={() => {
          if (auth) {
            console.log("logout");
            fetch("/logout");
          }
        }}
        className={
          checked ? "nav-link selected color-text" : "nav-link color-text"
        }
        aria-current={checked ? "page" : false}
      >
        {checked ? (
          <span className="assistive-text">Current page: </span>
        ) : null}
        {auth ? item.alt_label : item.label}
      </a>
    ),
    divider: <div className={"nav-divider"}></div>,
    external: (
      <a
        to={item.link}
        className="nav-link color-text"
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.label}
      </a>
    ),
  };

  return <li className="nav-item">{types[item.type]}</li>;
};

const NavList = (props) => {
  const {
    className,
    radioValue,
    setRadioValue,
    lastList,
    setLastList,
    currentId,
  } = props;

  //Catch edge case where user navigates to about_me and then directly to a blog page. Trailing projects are broken.
  useEffect(() => {
    const lists = listPages.map((p) => p.value);
    //if the radioValue changes to a list page, stash the last list
    if (lists.includes(radioValue) && radioValue !== lastList) {
      setLastList(radioValue);
    }
    //if the current page is not a nav item, set radio to last list
    if (currentId && !["about_me", "login", ...lists].includes(currentId)) {
      setRadioValue(lastList);
    }
    //if the current page is a nav item, set radio to current item
    else if (
      currentId &&
      !!["about_me", "login", ...lists].includes(currentId)
    ) {
      setRadioValue(currentId);
    }
  }, [currentId, radioValue, setRadioValue, lastList, setLastList]);

  return (
    <nav
      className={["flex-auto montserrat", className].join(" ")}
      aria-labelledby="nav-label"
    >
      <h2 className="assistive-text" id="nav-label">
        Main Navigation
      </h2>
      <ul className="list">
        <li>
          <h3 className="assistive-text" id="projects-nav-label">
            View Projects By Type
          </h3>
          <ul className="flex flex-column list nav-list">
            {listPages.map((item, i) => (
              <NavItem
                item={item}
                key={"nav-item_" + i}
                radioValue={radioValue}
              />
            ))}
          </ul>
        </li>
        <li>
          <div className="nav-divider mv3"></div>
          <h3 className="assistive-text" id="pages-nav-label">
            More Pages
          </h3>
          <ul className="flex flex-column list nav-list">
            {items.map((item, i) => (
              <NavItem
                item={item}
                key={"nav-item_" + i}
                radioValue={radioValue}
              />
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

const NavButton = (props) => {
  const [open, setOpen] = useState(false);
  const openClass = open ? "open" : "closed";
  const label = open ? "Close Menu" : "Open Menu";

  return (
    <button
      className={["nav-button", openClass, props.className].join(" ")}
      onClick={() => {
        setOpen(!open);
        if (props.onClick) props.onClick();
      }}
    >
      <div className="middle"></div>
      <div className="assistive-text">{label}</div>
    </button>
  );
};

export { NavButton, NavList };
