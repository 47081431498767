import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import backSvg from '../images/back.svg';

const SiteHeader = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              titleAlt
            }
          }
        }
      `}
      render={(data) => (
        <h1 className="ttu f1 header">{data.site.siteMetadata.titleAlt}</h1>
      )}
    />
  );
};

const PostHeader = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    typeof window !== 'undefined' && window.history.back();
  };

  return (
    <div className="relative post-header">
      <a href="/#" className="back-button mb3" onClick={onClick}>
        <img src={backSvg} alt="Back" width="32" height="32"></img>
        <span id="back-label" className="assistive-text">
          Back
        </span>
      </a>
      <h2 className="">{props.headertext || props.children || '404'}</h2>
    </div>
  );
};

const ListHeader = (props) => {
  return <h2 className="mv3 mh3">{`${props.title} Projects`}</h2>;
};

export { SiteHeader, PostHeader, ListHeader };
